import React, {useState} from "react"
import VisibilitySensor from "react-visibility-sensor";

import Layout from '../default'

import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import ContentElements from "../../components/content-elements";

import styles from "./component.module.less";

export default props => {

  const {header, intro} = (props.children || {});

  const locale = props.locale || 'de';
  const anchorItems = props.anchorItems || [];

  const [anchors, setAnchors] = useState(anchorItems);
  const [headerVisible, setHeaderVisible] = useState(true);

  // element visibility

  const onHeaderVisibilityChange = (visible) => {
    setHeaderVisible(visible);

    const items = anchorItems.map((a, i) => {
      a.active = i === (visible ? 0 : 1);
      return a;
    });
    setAnchors(items);
  };

  const onElementVisibilityChange = (visibleId, visible) => {
    if (!visible) return;

    const index = anchorItems.find(a => a.id === visibleId);
    if (!index) return;

    const items = anchorItems.map(a => {
      a.active = a.id === visibleId;
      return a;
    });
    setAnchors(items);
  };

  return (
      <Layout
          className={`${styles.layout}${props.className ? ' ' + props.className : ''}`}
          locale={locale}
          localeLinks={props.localeLinks}
          seo={props.seo}
          robots={props.robots}
          canonical={props.canonical}
          minimal={props.minimal}
          hideStickyNote={props.hideStickyNote}
      >
        {{
          header: (
              <>
                {header && <>{header}</>}

                {!header &&
                  <Header
                      sticky={false}
                      className={styles.header}
                      locale={locale}
                      localeLinks={props.localeLinks}
                  />
                }
              </>
          ),
          content: (
              <>
                <VisibilitySensor
                    resizeCheck={false}
                    partialVisibility={true}
                    offset={{top: 260, bottom: 260}}
                    onChange={onHeaderVisibilityChange}
                >
                  <>
                    {intro && <>{intro}</>}
                    {!header && !intro && <>{props.children}</>}
                  </>
                </VisibilitySensor>

                <Sidebar
                    tocLabel={props.sidebarTocLabel}
                    contactLabel={props.sidebarContactLabel}
                    contactButtonLabel={props.sidebarContactButtonLabel}
                    isTop={headerVisible}
                    baseColor={props.color}
                    items={anchors}
                    contact={props.sidebarContact}
                    contactPath={props.sidebarContactLink}
                />

                <ContentElements
                    locale={locale}
                    items={props.contentElements}
                    config={props.config}
                    onVisibilityChange={onElementVisibilityChange}
                    classNames={{
                      'DatoCmsProductmediaText': ce => (ce.alignment || '').toLowerCase() !== process.env.GATSBY_CONTENT_ALIGNMENT_LEFT ? styles.mediaTextReverse : styles.mediaText,
                      'DatoCmsMediaText': ce => ce.imageAside ? styles.mediaTextAside : '',
                      'DatoCmsImage': () => styles.img,
                    }}
                    mutators={{
                      'DatoCmsProductlist': (ce) => {ce.full = false; return ce},
                    }}
                />
              </>
          )
        }}
      </Layout>
  )

}
