import React from "react"
import {graphql} from "gatsby"

import Color from "color"

import id from "../lib/id";
import gatsbyImageDataUrlsWith from "../lib/gatsby-image-data-urls-with";

import Layout from '../layouts/sidebar'

import Container from "../components/content-container";
import Text from "../components/text";
import TextDecorator from "../components/text-decorator";

import MediaText from "../content-elements/media-text";

import styles from "./single-product/component.module.less";

export default ({data, pageContext}) => {

  const locale = pageContext.locale || 'de';

  const config = data.allDatoCmsConfig.nodes[0];
  const contactUrl = config.pageAppointment.slug || config.pageContact.slug || null;

  const product = data.datoCmsProdukt;
  const productColor = Color(product.color.hex);

  if (product.detailImage && product.detailImage.gatsbyImageData) {
    product.detailImage.gatsbyImageData = gatsbyImageDataUrlsWith(
        product.detailImage.gatsbyImageData,
        url => !url.includes('&fp-z=1.3') ? url + '&fp-z=1.3' : url
    );
  }

  // anchors

  const introAnchor = pageContext.templateStrings.productPageSidebarIntroLabel;

  let anchorItems = product.content.map(ce => ce ? ce.anchorName : undefined).filter(a => a !== undefined && a.length > 0);
  anchorItems.unshift(introAnchor);
  anchorItems = anchorItems.map((a, i) => {
    return {
      id: id(a),
      name: a,
      active: i === 0,
    }
  });

  // render

  return (
      <Layout
          className={styles.productPage}
          locale={locale}
          localeLinks={pageContext.localeLinks}
          seo={pageContext.seoMetaTags}
          robots={pageContext.seoMetaRobots}
          canonical={pageContext.seoMetaCanonical}
          anchorItems={anchorItems}
          config={config}
          contentElements={product.content}
          sidebarTocLabel={pageContext.templateStrings.productPageSidebarTocLabel}
          sidebarContactLabel={pageContext.templateStrings.productPageSidebarContactLabel}
          sidebarContactButtonLabel={pageContext.templateStrings.productPageSidebarContactButtonLabel}
          sidebarContact={product.contact || config.contact}
          sidebarContactLink={contactUrl ? '/' + locale + '/' + contactUrl + '/' : null}
          color={productColor}
      >
        <>
          {product.detailImage &&
            <MediaText
                id={id(introAnchor)}
                className={styles.topic}
                header={true}
                dark={productColor.luminosity() < 0.5}
                style={{
                  background: productColor.hex() + ' linear-gradient(270deg, ' + productColor.hex() + ' 0%, rgba(0,0,0,0) 100%)',
                }}
                image={product.detailImage}
                imageLoading="eager"
                imageBorder={false}
                emphasizeMedia={true}
                title={product.category}
                headline={product.headline || product.name}
                headlineType="h1"
                htmlText={product.introduction}
                childPosition="before"
            >
              <TextDecorator className={styles.decorator} widthOffset={-130} baseColor={productColor}>{product.name}</TextDecorator>
            </MediaText>
          }

          {!product.detailImage &&
            <Container
                id={id(introAnchor)}
                className={styles.topicTxt}
                style={{
                  background: productColor.hex() + ' linear-gradient(270deg, ' + productColor.hex() + ' 0%, rgba(0,0,0,0) 100%)',
                }}
            >
              <TextDecorator className={styles.decorator} widthOffset={-130} baseColor={productColor}>{product.name}</TextDecorator>
              <Text
                  title={product.category}
                  headline={product.name}
                  headlineType="h1"
                  htmlText={product.introduction}
                  dark={productColor.luminosity() < 0.5}
              />
            </Container>
          }
        </>
      </Layout>
  );
}

export const query = graphql`
    query($locale: String!, $id: String!) {
  
        allDatoCmsConfig(filter: {locale: {eq: $locale}}) {
            nodes {
                ...config_data
            }
        }
  
        datoCmsProdukt(id: {eq: $id }) {
            ...product_content

            id
            name
            category
            headline
            introduction
            slug
            color {
                hex
            }
            detailImage {
                alt
                gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    imgixParams: {w: "1020", h: "1020", auto: "compress", dpr: 1.5, q: 75, fit: "crop", crop: "focalpoint"}
                )
            }
            contact {
                ...contact_minimal
            }
        }

    }
`
